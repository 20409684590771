@import '../Crypto.module.scss';

.block {
    margin: 54px 0 40px 0;
}

.wrapper{
    padding: 40px 0;
}

.logos{
    margin-bottom: 16px;
    img{
        width: 200px;
        height: auto;
    }
}

.title {
    font-size: 28px;
    font-weight: 700;
    line-height: 38px; /* 135.714% */
    color: #fff;
    margin-bottom: 16px;
}

.text {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    span{
        color: $accent-color;
    }
}

@media screen and (min-width: 576px) {
    .block{
        margin: 80px 0 40px 0;
    }

    .wrapper{
        padding: 54px 0 40px 0;
    }
    .title{
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
    }

    .text {
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
    }
}

@media screen and (min-width: 768px) {
    .block{
        margin-top: 120px;
    }

    .logos{
        margin-bottom: 35px;
        img{
            width: 313px;
            height: auto;
        }
    }

    .title{
        font-size: 52px;
        font-weight: 700;
        line-height: 62.4px;
    }

    .text {
        font-size: 24px;
        font-weight: 400;
        line-height: 38px;
    }
}

@media screen and (min-width: 992px) {
    .block{
        margin-top: 160px;
    }

    .title{
        font-size: 64px;
        font-weight: 700;
        line-height: 77px;
    }

    .text {
        font-size: 32px;
        font-weight: 400;
        line-height: 48px;
    }
}

@media screen and (min-width: 1200px) {
    .block{
        margin-top: 200px;
        margin-bottom: 90px;
    }
    .wrapper{
        padding: 60px 95px;
    }
    .title{
        margin-bottom: 37px;
    }
}

@media screen and (min-width: 1600px) {
    .wrapper{
        padding: 60px 55px;
    }
}
