@import '../Crypto.module.scss';

.stats {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
}

@media screen and (min-width: 576px) {
    .stats {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 75px;
        margin-bottom: 40px;
    }
}

@media screen and (min-width: 1200px) {
    .stats {
        margin-top: 75px;
    }
}

@media screen and (min-width: 1600px) {
    .stats {
        margin-top: 105px;
        & > div:nth-child(2) {
            width: 311px;
            flex: unset;
        }
    }
}
