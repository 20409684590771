@import '../../colors.scss';

.networkBlock {
    margin: 20px 0;
    padding: 0 5px;
    &_img {
        text-align: center;
        margin-bottom: 20px;
        img {
            display: inline-block;
            width: auto;
            height: 134px;
        }
    }
    &_text {
        text-align: center;
        padding: 0 5px;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #fff;
            margin-bottom: 9px;
        }
        p {
            font-size: 14px;
            color: #ccc;
        }
    }
}

@media screen and (min-width: 576px) {
    .networkBlock {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        &_img {
            position: relative;
            text-align: left;
            width: 40%;
        }
        &_text {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            position: relative;
            width: 60%;
            text-align: left;
        }
    }
}

@media screen and (min-width: 768px) {
    .networkBlock {
        height: 192px;
        &_img {
            text-align: center;
            width: 50%;
            margin-right: 0;
            img {
                width: auto;
                height: 192px;
            }
        }
        &_text {
            width: 50%;
            h3 {
                font-size: 24px;
            }
            p {
                font-size: 16px;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .networkBlock {
        height: unset;
        &_img {
            img {
                height: 269px;
            }
        }
        &_text {
            padding-right: 100px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .networkBlock {
        height: fit-content;
        flex-direction: column;
        width: calc((100% / 3) - 32px);
        &_img {
            text-align: center;
            width: 100%;
            margin-bottom: 0;
            img {
                height: 210px;
            }
        }
        &_text {
            text-align: center;
            width: 100%;
            margin-top: 25px;
            padding-right: 0;
        }
    }
}

@media screen and (min-width: 1600px) {
    .networkBlock {
        &_img {
            img {
                height: 240px;
            }
        }
    }
}
