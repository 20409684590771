@import '../Crypto.module.scss';

.header {
    display: flex;
    flex-wrap: wrap;
    padding: 32px 0 28px 0;
    &_title {
        h2 {
            font-size: 28px;
            font-weight: 600;
            color: #fff;
            margin-bottom: 32px;
            .red {
                color: $accent-color;
            }
        }
    }
}

@media screen and (min-width: 576px) {
    .header {
        &_title {
            h2 {
                font-size: 52px;
                line-height: 120%;
            }
        }
        &_button{
            width: 100%;
            button{
                width: 100%;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .header {
        padding-top: 64px;
        &_title {
            h2 {
                margin-bottom: 40px;
            }
        }
        &_button{
            width: unset;
            button{
                width: auto;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .header {
        padding-top: 100px;
        background-image: url('./header.png');
        background-repeat: no-repeat;
        background-position: 122% 30px;
        background-size: 50%;
        &_wrapper {
            display: flex;
            align-items: center;
        }
    }
}

@media screen and (min-width: 1200px) {
    .header {
        padding-top: 115px;
        background-position: 100% 60px;
        background-size: 537px 438px;
        &_title {
            width: 60%;
            h2 {
                font-size: 64px;
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .header {
        padding: 105px 0 75px 0;
        background-position: 83% 65px;
        background-size: 538px 440px;
        &_title {
            margin-top: -20px;
        }
    }
}
