.wrapper {
    padding: 25px 0;
    .title {
        text-align: center;
        color: #000!important;
        margin-bottom: 32px;
        font-size: 24px;
    }
    .icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 50px;
        img{
            margin: 0 24px 24px 0;
            height: 74px;
            width: 74px;
            &:nth-child(even){
                margin-right: 0;
            }
            &:nth-last-child(-n+2){
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (min-width: 576px) {
    .wrapper{
        .title{
            font-size: 40px;
            margin-bottom: 27px;
        }
        .icons{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            padding: 0;
            img{
                margin-right: auto;
                margin-left: auto;
                &:nth-child(even){
                    margin-right: auto;
                }
                &:nth-last-child(-n+3){
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .wrapper{
        padding: 40px 0;
        .title{
            font-size: 52px;
            line-height: 62px; /* 119.231% */
        }
        .icons {
            display: flex;
            justify-content: center;
            gap: 0;
                img{
                    margin-right: 29px;
                    margin-left: 0;
                    &:nth-child(even){
                        margin-right: 29px;
                    }
                    &:nth-last-child(1){
                        margin-right: 0;
                    }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .wrapper{
        .title{
            font-size: 64px;
            line-height: 77px; /* 120.313% */
        }
        .icons{
            img{
                height: 100px;
                width: 100px;
                margin-right: 40px;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .wrapper{
        .icons{
            img{
                margin-right: 64px;
                &:nth-child(even){
                    margin-right: 64px;
                }
                &:nth-last-child(1){
                    margin-right: 64px;
                }
            }
        }
    }
}
