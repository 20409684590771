@import './colors.scss';

.landingContainer {
    position: relative;
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 320px) {
        width: 304px;
    }
    @media (min-width: 576px) {
        width: 560px;
    }
    @media (min-width: 768px) {
        width: 672px;
    }
    @media (min-width: 992px) {
        width: 928px;
    }
    @media (min-width: 1200px) {
        width: 1108px;
    }
    @media (min-width: 1600px) {
        width: 1264px;
    }
}

.darkWrapper {
    position: relative;
    background: #000;
    padding-bottom: 5px;
    &::after{
        content: '';
        position: absolute;
        display: block;
        border-top: 43px solid #000;
        border-left: 98vw solid transparent;
        width: 100%;
        bottom: -40px;
    }
    &__twin{
        padding: 45px 0;
        margin: 45px 0;
        &::before{
            content: '';
            position: absolute;
            display: block;
            border-bottom: 43px solid #000;
            border-right: 98vw solid transparent;
            width: 100%;
            top: -40px;
        }
    }
}
