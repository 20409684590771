@import '../Crypto.module.scss';

.forWhom {
    margin: 42px 0 20px 0;
    &_header{
        &_title{
            text-align: center;
            color: #fff!important;
        }
    }
}

@media screen and (min-width: 576px) {
    .forWhom {
        &_content {
            padding: 40px 0 25px 0;
        }
    }
}

@media screen and (min-width: 768px) {
    .forWhom {
        margin-top: 80px;
        &_content {
            padding: 50px 0;
        }
        &_list{
            margin-top: 48px;
        }
    }
}

@media screen and (min-width: 992px) {
    .forWhom {
        &_header {
            &_title {
                margin-bottom: 50px;
            }
        }
        &_list {
            padding: 0 70px;
        }
    }
}

@media screen and(min-width: 1200px) {
    .forWhom {
        margin-top: 60px;
        &_list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 120px;
            padding: 0;
        }
    }
}

@media screen and(min-width: 1600px) {
    .forWhom {
        &_content {
            padding: 74px 0;
        }
        &_list {
            grid-column-gap: 25px;
        }
    }
}
