.container {
    position: relative;
    overflow: hidden;
    &_layout {
        max-width: 1160px;
        margin: 30px auto;
        transform: rotate(6deg) skewX(6deg);
        border-radius: 40px;
        width: 95%;
        &__black {
            background: #000;
        }
        &__greyGradient {
            background: linear-gradient(252deg, rgba(255, 255, 255, 0.20) 2.42%, rgba(255, 255, 255, 0.00) 109.9%);
        }
        &__bordered {
            border-color: #000;
            border-width: 3px;
        }
    }
    &_children {
        transform: rotate(-6deg) skewX(-6deg);
        padding: 10px 0;
    }
}

@media screen and (min-width: 576px) {
    .container {
        &_layout {
            margin: 30px auto;
            transform: rotate(4deg) skewX(4deg);
            &__bordered {
                border-color: #000;
                border-width: 6px;
            }
        }
        &_children {
            transform: rotate(-4deg) skewX(-4deg);
        }
    }
}

@media screen and (min-width: 768px) {
    .container {
        &_layout {
            margin: 30px auto;
        }
    }
}

@media screen and (min-width: 992px) {
    .container {
        &_layout {
            transform: rotate(2deg) skewX(2deg);
            margin: 20px auto;
        }
        &_children {
            transform: rotate(-2deg) skewX(-2deg);
        }
    }
}

@media screen and (min-width: 1200px) {
    .container {
        &_layout {
            transform: rotate(2deg) skewX(2deg);
            border-radius: 40px;
            margin-left: auto;
            margin-right: auto;
        }
        &_children {
            transform: rotate(-2deg) skewX(-2deg);
        }
    }
}

@media screen and (min-width: 1600px) {
    .container {
        padding: 25px 80px;
        &_layout {
            margin: auto;
            max-width: 1440px;
        }
    }
}
